import React from 'react';

const Apps = React.lazy(() => import('./pages/Apps/index'));

//Utility
const PagePrivacy = React.lazy(() => import('./pages/Pages/Utility/PagePrivacy'));
const PageTerms = React.lazy(() => import('./pages/Pages/Utility/PageTerms'));

const routes = [

    //Utility
    { path: '/terms', component: PageTerms },
    { path: '/privacy', component: PagePrivacy, isWithoutLayout : true },
    
    //Index Main
    { path: '/index', component: Apps },

    //Root
    { path: '/', component: Apps}

];

export default routes;